import '../App.css';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {performApi} from "../utils/Util";
import {Button, Form} from "react-bootstrap";

function Login({theme}) {

  const navigate = useNavigate();
  const lightThemeInputBg = ''

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const login = async () => {
    let body = {
      email: email,
      password: password
    }
    try {
      let response = await performApi('POST', '/auth/login', JSON.stringify(body))
      localStorage.setItem('token', response.token)
      setError('')
      window.location.href = '/'
    } catch (e) {
      setError(e)
    }
  }

  const restore = () => {
    window.location.href = '/resotreform'
  }

  return (
      <div style={{paddingLeft:'40%', width:'100%'}}>
        <div>
          <Form.Control size={'xsm'} style={{width:250, marginLeft:5, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} placeholder='email' value={email} onChange={(val) => setEmail(val.target.value)}/>
        </div>
        <div style={{paddingTop:3}}>
          <Form.Control size={'xsm'} style={{width:250, marginLeft:5, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} placeholder='password' type='password' value={password} onChange={(val) => setPassword(val.target.value)}/>
        </div>
        <div style={{paddingLeft:6, paddingTop:3}}>
          <div style={{display: 'flex'}}>
            <div>
              <Button size={'sm'} onClick={() => login()}>login</Button>
            </div>
            <div style={{paddingLeft:5}}>
              <Button size={'sm'} onClick={() => restore()}>restore</Button></div>
            </div>
          <div style={{display: 'flex', width:400}}>{error.length > 0 && <text style={{color:'red'}}>{error}</text>}</div>
        </div>
      </div>
  );
}

export default Login;
