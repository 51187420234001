import '../App.css';
import {useState} from "react";
import {performApi} from "../utils/Util";
import {Button, Form} from "react-bootstrap";

function Registration({theme}) {

  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [error, setError] = useState('')

  const lightThemeInputBg = ''

  const register = async () => {
    if (password !== repeatPassword) {
      setError('Passwords are different')
      return
    }
    if (password.length <= 0) {
      setError("Password can't be empty")
      return
    }
    try {
      await performApi('POST', '/auth/reset/' + window.location.pathname.replace('/recover/', '') , password)
      setError('')
    } catch (e) {
      setError(e)
      window.location.href = '/'
    }
  }

  return (
      <div style={{paddingLeft:'40%', width:'100%'}}>
        <div>
          <Form.Control size={'xsm'} style={{width:250, marginLeft:5, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} placeholder='new password' type='password' value={password} onChange={(val) => setPassword(val.target.value)}/>
        </div>
        <div style={{paddingTop:3}}>
          <Form.Control size={'xsm'} style={{width:250, marginLeft:5, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} placeholder='repeat new password' type='password' value={repeatPassword} onChange={(val) => setRepeatPassword(val.target.value)}/>
        </div>
        <div style={{paddingLeft:6, paddingTop:3}}>
          <div><Button size={'sm'} onClick={() => register()}>save new password</Button></div>
          <div>{error.length > 0 && <text style={{color:'red'}}>{error}</text>}</div>
        </div>
      </div>
  );
}

export default Registration;
