import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Craft from "./pages/Craft";
import Recover from "./pages/Recover";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import {useState} from "react";
import bgimg from "./Untitledq2.png";
import bright from "./newBGFromEuheniy.png";
import Switch from "react-switch";
import moon from "./moon.png";
import sun from "./sun.png";
import RecoverForm from "./pages/RecoverForm";
import UserPrices from "./pages/UserPrices";
import UserCrafts from "./pages/UserCrafts";
import UserCraftDet from "./pages/UserCraftDet";
import {Button, Form, Modal} from "react-bootstrap";
import {performApi} from "./utils/Util";
import {Text} from "./elem/Elem";

function App() {

  const [token, setToken] = useState(localStorage.getItem("token"))
  const [themeTogle, setThemeTogle] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') === 'true' : true)

  const [showFeedBack, setShowFeedBack] = useState(false);

  const [feedBackEmail, setFeedBackEmail] = useState('');
  const [feedBackText, setFeedBackText] = useState('');

  const handleCloseFeedBack = () => setShowFeedBack(false);

  const changeTheme = () => {
    setThemeTogle(!themeTogle)
    localStorage.setItem("theme", !themeTogle)
  }

  const logout = () => {
    localStorage.removeItem("token")
    setToken(null)
    if (window.location.pathname !== '/') {
      window.location.href = '/'
    }
  }

  const itemPrices = () => {
    window.location.href = '/user/prices'
  }

  const login = () => {
    window.location.href = '/login'
  }

  const registration = () => {
    window.location.href = '/registration'
  }

  const userCrafts = () => {
    window.location.href = '/user/crafts'
  }

  const main = () => {
    window.location.href = '/'
  }

  const sendFeedBack = () => {
    let body = {
      email: feedBackEmail,
      text: feedBackText
    }
    performApi('POST', '/feedback', JSON.stringify(body))
    setShowFeedBack(false)
  }

  const renderWindowForSaveCraft = () => {
    return <Modal show={showFeedBack} onHide={handleCloseFeedBack}>
      <Modal.Header closeButton>
        <Modal.Title>Feedback form</Modal.Title>
      </Modal.Header>
      <Form.Group controlId="email">
        <Form.Control
            type="text"
            placeholder="Enter your email for a feedback (not require)"
            name="email"
            onChange={(val) => setFeedBackEmail(val.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="text" style={{paddingTop:5}}>
        <Form.Control
            type="text"
            placeholder="Enter your suggestion"
            name="text"
            as="textarea"
            onChange={(val) => setFeedBackText(val.target.value)}
        />
      </Form.Group>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseFeedBack}>
          Close
        </Button>
        <Button variant="primary" onClick={sendFeedBack}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  }

  const renderPage = (elem) => {
    return <div className={'divwithoutscroll'} style={{backgroundImage: themeTogle ? 'url(' + bgimg + ')' : 'url(' + bright + ')'}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{paddingLeft: 10, marginLeft: 0, marginRight: 'auto'}}>
          <Switch onChange={changeTheme}
                  checked={themeTogle}
                  onColor={'#474d5e'}
                  offColor={'#61dafb'}
                  checkedIcon={<img alt="" src={moon} style={{width:25, paddingLeft:5}}/>}
                  uncheckedIcon={<img alt="" src={sun} style={{width:25, paddingLeft:5}}/>}
                  handleDiameter={20}
          />
        </div>
        <div style={{marginLeft: 'auto', marginRight: 0}}>
          <div style={{display: 'flex'}}>
            {window.location.pathname.length > 1 &&
            <div>
              <Button variant="secondary" size={'sm'} onClick={main}>main</Button>
            </div>
            }
            {(!token && window.location.pathname.length <= 1) &&
            <div>
              <Button variant="secondary" size={'sm'} onClick={login}>login</Button>
              <Button variant="secondary" size={'sm'} onClick={registration}>registration</Button>
            </div>}
            {(token !== null && token.length > 0) &&
            <div>
              {window.location.pathname !== '/user/prices' && <Button variant="secondary" size={'sm'} onClick={() => itemPrices()}>item prices</Button>}
              {window.location.pathname !== '/user/crafts' && <Button variant="secondary" size={'sm'} onClick={() => userCrafts()}>user crafts</Button>}
              <Button variant="secondary" size={'sm'} onClick={() => logout()}>log out</Button>
            </div>
            }
          </div>
        </div>
      </div>
      <div className={'divwithoutscroll'} style={{overflowY:'auto', height:'calc(100vh - 30px)'}}>
        {renderWindowForSaveCraft()}
        {elem}
        <div style={{height:30}}></div>
        <Button style={{position: 'absolute', right: 0, bottom: 0}} onClick={() => setShowFeedBack(true)}>feedback</Button>
      </div>
    </div>;
  }

  return (
      <Router>
        <Routes>
          <Route exact path="/" element={renderPage(<Craft theme={themeTogle}/>)}/>
          <Route exact path="/recover/*" element={renderPage(<Recover theme={themeTogle}/>)}/>
          <Route exact path="/login" element={renderPage(<Login theme={themeTogle}/>)}/>
          <Route exact path="/registration" element={renderPage(<Registration theme={themeTogle}/>)}/>
          <Route exact path="/resotreform" element={renderPage(<RecoverForm theme={themeTogle}/>)}/>
          <Route exact path="/user/prices" element={renderPage(<UserPrices theme={themeTogle}/>)}/>
          <Route exact path="/user/crafts" element={renderPage(<UserCrafts theme={themeTogle}/>)}/>
          <Route exact path="/user/craft/*" element={renderPage(<UserCraftDet theme={themeTogle}/>)}/>
        </Routes>
      </Router>
  );
}

export default App;


