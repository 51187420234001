import React from 'react'

export const Td = (props) => {
    return <td className={'Td'} {...props}>{props.children}</td>
}

export const Tr = (props) => {
  return <td className={'Tr'} {...props}>{props.children}</td>
}

export const Text = (props) => {
  return <text className={'Text'} {...props}>{props.children}</text>
}

export const Img = (props) => {
  return <img className={'Img'} {...props} alt="">{props.children}</img>
}

export const Input = (props) => {
  return <input className={'Input'} {...props} alt="">{props.children}</input>
}
