export const performApi = (type, url, body) => {
  const requestOptions = {
    method: type,
    headers: {
      "Content-Type":"application/json",
      Accept: 'application/json',
      "Authorization":localStorage.getItem("token")
    },
    body: body
  };
  console.log(" TRYING TO FETCH :", requestOptions)
  //https://jfolio.org/l2calc/api
  //http://localhost:8080/l2calc/api
  return fetch('https://jfolio.org/l2calc/api' + url, requestOptions).then( async (response) => {
    if (response.ok) {
      try {
        return response.json();
      } catch (e) {
        return '';
      }
    } else {
      return Promise.reject(await response.text())
    }
  })
}

export const getItemFromListByProp = (list, search, prop) => {
  let found;
  for(let i = 0; i < list.length; i++) {
    if (list[i][prop] === search) {
      found = list[i]
      break;
    }
  }
  return found
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const ignoreLangString = (x) => {
  return x.replaceAll('й', 'q')
      .replaceAll('ц', 'w')
      .replaceAll('у', 'e')
      .replaceAll('к', 'r')
      .replaceAll('е', 't')
      .replaceAll('н', 'y')
      .replaceAll('г', 'u')
      .replaceAll('ш', 'i')
      .replaceAll('щ', 'o')
      .replaceAll('з', 'p')
      .replaceAll('ф', 'a')
      .replaceAll('ы', 's')
      .replaceAll('в', 'd')
      .replaceAll('а', 'f')
      .replaceAll('п', 'g')
      .replaceAll('р', 'h')
      .replaceAll('о', 'j')
      .replaceAll('л', 'k')
      .replaceAll('д', 'l')
      .replaceAll('я', 'z')
      .replaceAll('ч', 'x')
      .replaceAll('с', 'c')
      .replaceAll('м', 'v')
      .replaceAll('и', 'b')
      .replaceAll('т', 'n')
      .replaceAll('ь', 'm')
}
