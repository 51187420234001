import '../App.css';
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {performApi} from "../utils/Util";
import {Button, Form} from "react-bootstrap";

function Registration({theme}) {

  const navigate = useNavigate();
  const lightThemeInputBg = ''

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [error, setError] = useState('')

  const register = async () => {
    if (password !== repeatPassword) {
      setError('Passwords are different')
      return
    }
    if (email.length <= 0) {
      setError("Email can't be empty")
      return
    }
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      setError("Email is invalid")
      return
    }
    if (password.length <= 0) {
      setError("Password can't be empty")
      return
    }

    let body = {
      email: email,
      password: password
    }
    try {
      let response = await performApi('POST', '/auth/register', JSON.stringify(body))
      localStorage.setItem('token', response.token)
      setError('')
      window.location.href = '/'
    } catch (e) {
      setError(e)
    }
  }

  return (
      <div style={{paddingLeft:'40%', width:250}}>
        <div>
          <Form.Control size={'xsm'} style={{width:250, marginLeft:5, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} placeholder='email' type='email' value={email} onChange={(val) => setEmail(val.target.value)}/>
        </div>
        <div style={{paddingTop:3}}>
          <Form.Control size={'xsm'} style={{width:250, marginLeft:5, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} placeholder='password' type='password' value={password} onChange={(val) => setPassword(val.target.value)}/>
        </div>
        <div style={{paddingTop:3}}>
          <Form.Control size={'xsm'} style={{width:250, marginLeft:5, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} placeholder='repeat password' type='password' value={repeatPassword} onChange={(val) => setRepeatPassword(val.target.value)}/>
        </div>
        <div style={{paddingLeft:6, paddingTop:3}}>
          <div><Button size={'sm'} onClick={() => register()}>register</Button></div>
          <div style={{width:300}}>{error.length > 0 && <text style={{color:'red'}}>{error}</text>}</div>
        </div>
      </div>
  );
}

export default Registration;
