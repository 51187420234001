import '../App.css';
import {useEffect, useState} from "react";
import {performApi} from "../utils/Util";
import {Button, Table} from "react-bootstrap";
import {FaTrashAlt} from "react-icons/fa";

function UserCrafts({theme}) {

  const brightThemeTextColor = '#000b44';

  const [savedList, setSavedList] = useState([])
  const [loadPage, setLoadPage] = useState(false)

  useEffect(() => {
    if (!loadPage) {
      getUserPrices()
      setLoadPage(true)
    }
  }, []);

  const getUserPrices = async () => {
    let res = await performApi('GET', '/user/craft')
    setSavedList(res)
    return res;
  }

  const removeSavedList = (d) => {
    setSavedList(savedList.filter(e => e.craftName !== d.craftName))
    performApi('DELETE', '/user/craft/' + d.id)
  }

  const userCrafts = (d) => {
    window.location.href = '/user/craft/' + d.id
  }

  const renderCraftsList = (d) => {
    return <tr>
      <td style={{cursor: 'pointer'}} onClick={() => userCrafts(d)}><text style={{color:theme ? '#f3ca00' : brightThemeTextColor}}>{d.craftName}</text></td>
      <td width={20}><Button variant={'danger'} size={'xsm-remove'} onClick={() => removeSavedList(d)}><FaTrashAlt /></Button></td>
    </tr>
  }

  return (
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <Table striped bordered hover size="sm" style={{width:350}} variant={theme ? 'dark' : ''}>
          <tbody>
          {savedList.map((d) => renderCraftsList(d))}
          </tbody>
        </Table>
      </div>
  );
}

export default UserCrafts;
