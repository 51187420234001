import '../App.css';
import {useEffect, useState} from "react";
import {Text} from "../elem/Elem";
import {getItemFromListByProp, performApi} from "../utils/Util";
import Autocomplete from "react-autocomplete";
import {Button, Form} from "react-bootstrap";
import {FaTrashAlt} from "react-icons/fa";

function UserPrices({theme}) {

  const brightThemeTextColor = '#000b44';
  const lightThemeInputBg = ''

  const [searchValue, setSearchValue] = useState('')
  // result of search for craft
  const [itemList, setItemList] = useState([])

  const [savedList, setSavedList] = useState([])
  const [loadPage, setLoadPage] = useState(false)

  const getItemListRequest = async (text) => {
    return performApi('GET', '/craft/listres/' + text)
  }

  const getItemList = (text) => {
    if (text.length > 1) {
      getItemListRequest(text).then(res => {
        setItemList(res)
      })
    } else {
      setItemList([])
    }
  }

  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => getItemList(searchValue), searchValue.length > 1 ? 150 : 0);
      return () => clearTimeout(timeOutId);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!loadPage) {
      getUserPrices()
      setLoadPage(true)
    }
  }, []);

  const getUserPrices = async () => {
    let res = await performApi('GET', '/user/price')
    setSavedList(res)
    return res;
  }

  const addRecord = async (e) => {
    if (!getItemFromListByProp(savedList, e.name, 'name')) {
      let body = {
        id: e.id,
        price: e.price,
        name: e.name
      }
      let res = await performApi('POST', '/user/price/add', JSON.stringify(body))
      setSavedList([...savedList, res])
    }
  }

  const removeSavedList = (d) => {
    setSavedList(savedList.filter(e => e.name !== d.name))
    performApi('DELETE', '/user/price/' + d.id)
  }

  const updPrice = (d, val) => {
    let newVal
    setSavedList(savedList.map(tmpItem => {
      if (tmpItem.id === d.id) {
        newVal = {...tmpItem, price:val.target.value.replaceAll(",","")}
        return newVal
      }
      return tmpItem
    }))
    if (newVal) {
      performApi('PUT', '/user/price/' + d.id, JSON.stringify(newVal))
    }
  }

  const renderPricesTable = (d) => {
    return <div style={{display: 'flex', alignItems:'center'}}>
      <Text style={{}}><img className={theme ? "Img" : "ImgLght"} src={d.imgLink} alt=""/></Text>
      <Text style={{width:150, color:theme ? '' : brightThemeTextColor}}>{d.name}</Text>
      {<Form.Control size={'xsm'} style={{width:100, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} value={d.price} onChange={(vl) => updPrice(d, vl)}/>}
      <Button variant={'danger'} size={'sm'} onClick={() => removeSavedList(d)}><FaTrashAlt /></Button>
    </div>
  }

  return (
      <div>
        <div style={{paddingLeft:'40%', width:250}}>
          <Autocomplete
              inputProps={{placeholder:'Search', style:{width: 350}}}
              value={searchValue}
              getItemValue={item => item.name}
              renderItem={(item) =>
                  <div style={{textAlign: "left", width: 350}}>
                    <img className={theme ? "Img" : "ImgLght"} src={item.imgLink} alt=""/>
                    {item.name}
                  </div>
              }
              onChange={(event, vl) => setSearchValue(vl)}
              onSelect={(event, vl) => addRecord(vl)}
              items={itemList}/>
        </div>
        <div style={{paddingLeft:10}}>
          {savedList.map((d) => renderPricesTable(d))}
        </div>
      </div>
  );
}

export default UserPrices;
