import '../App.css';
import {useState} from "react";
import {Text} from "../elem/Elem";
import {performApi} from "../utils/Util";
import {Button, Form} from "react-bootstrap";

function RecoverForm({theme}) {

  const brightThemeTextColor = '#000b44';
  const lightThemeInputBg = ''

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const recoverForm = async () => {
    try {
      await performApi('POST', '/auth/reset-request', email)
      setError('')
    } catch (e) {
      setSuccessMessage('on your email will be send an email with recovery link')
      setError(e)
    }
  }

  return (
      <div style={{paddingLeft:'40%', width:'100%'}}>
        {!successMessage && <div>
        <div>
          <Form.Control size={'xsm'} style={{width:250, marginLeft:5, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} placeholder='email' value={email} onChange={(val) => setEmail(val.target.value)}/>
        </div>
        <div style={{paddingLeft:6, paddingTop:3}}>
          <div style={{display: 'flex',  justifyContent:'center'}}>{error.length > 0 && <text style={{color:'red'}}>{error}</text>}</div>
          <div style={{width:'100%'}}><Button size={'sm'} onClick={() => recoverForm()}>send email with recover link</Button></div>
        </div>
        </div>}
        <Text style={{color:theme ? '' : brightThemeTextColor}}>{successMessage}</Text>
      </div>
  );
}

export default RecoverForm;
